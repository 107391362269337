h1 {
  color: hotpink;
  font-family: cursive;
}


.measure[data-v-cdc1f5] {
    margin: 1em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
h4[data-v-cdc1f5] {
    font-weight: lighter;
}


.partSelector tr[data-v-bbc706]:hover {
  cursor: pointer;
}
h4[data-v-bbc706] {
  font-weight: lighter;
}

/*# sourceMappingURL=index.5dcd0a86.css.map */
